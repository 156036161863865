import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import axios from "axios";
import { Delete, Edit } from "@material-ui/icons";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@material-ui/core";
import Backdrop from "../Backdrop/Backdrop";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import TablePagination from "@material-ui/core/TablePagination";
import { Typography } from "@material-ui/core";
import { Loading } from "./../Loading";
import sample from "../../Assets/samples/Sample - Subject List.csv";
import CircularProgress from "@material-ui/core/CircularProgress";
import { baseUrl } from "../../redux/baseUrl";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import "./addSubject.css";

const root = {
  padding: "2px 4px",
  display: "flex",
  alignItems: "center",
  width: "",
  flexGrow: 1,
  backgroundColor: "#fafafa",
  boxShadow: "4px 2px 16px 2px rgba(0,0,0,.1)",
  border: "1px solid rgba(0,0,0,.1)",
  marginTop: "16px",
};

const input = {
  flex: 1,
};
const iconButton = {
  padding: 10,
};
const divider = {
  height: 28,
  margin: 4,
};

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#a02929",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class AddSubject extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subjects: [],
      depts: [],
      sems: [],
      scheme: "",
      subject_name: "",
      subject_code: "",
      semester: "",
      department: "",
      degree: "",
      year: "",
      showModal: false,
      filteredSubjects: [],
      page: 0,
      rowsPerPage: 10,
      showPagination: true,
      isLoading: true,
      isDisabled: false,
      file: "",
      file_err_msg: "",
      isModalLoading: false,
      isEdit: false,
      subId: "",
      currentSubject: null,
    };
  }

  componentDidMount() {
    this.getSubjects();
    this.getDepts("");
  }

  getSubjects = () => {
    axios
      .get(baseUrl + "/subjects", { withCredentials: true })
      .then((res) => {
        if (res.data.length === 0) {
          this.setState({ subjects: ["No Entries"] });
          this.setState({ filteredSubjects: ["No Entries"] });
          this.setState({ isLoading: false });
        } else {
          this.setState({ subjects: [...res.data] });
          this.setState({ filteredSubjects: [...res.data] });
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getDepts = (degree) => {
    const url = `/departments${degree ? "?degree=" : ""}${degree}`;
    axios
      .get(baseUrl + url, { withCredentials: true })
      .then((res) => {
        this.setState({ depts: [...res.data] });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  searchBarHandler = (e) => {
    const keyString = e.target.value.toLowerCase().trim();
    const res = [];
    this.state.subjects.forEach((sub) => {
      if (
        (sub.scheme && sub.scheme.toLowerCase().includes(keyString)) ||
        sub.name.toLowerCase().includes(keyString) ||
        sub.code.toLowerCase().includes(keyString) ||
        sub.department.Dept_Name.toLowerCase().includes(keyString) ||
        sub.semester == keyString
      ) {
        res.push(sub);
      }
    });

    if (res.length === 0) {
      res.push("No Entries");
    }
    this.setState({ filteredSubjects: [...res] });
  };

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submitHandler = (e) => {
    e.preventDefault();
    this.setState({
      isModalLoading: true,
    });
    const yr =
      +this.state.semester % 2 === 0
        ? +this.state.semester / 2
        : (+this.state.semester + 1) / 2;

    let data;
    let url = "";
    let file = this.state.file;
    if (file) {
      const formData = new FormData();
      formData.set("subjects_file", file[0]);
      data = formData;
      url = "/subjects/upload";
    } else {
      url = this.state.isEdit
        ? `/subjects/${this.state.currentSubject._id}`
        : "/subjects";
      data = {
        scheme: this.state.scheme,
        name: this.state.subject_name,
        code: this.state.subject_code,
        semester: this.state.semester,
        year: yr,
        department: this.state.department,
        degree: this.state.degree,
      };
    }
    const method = this.state.isEdit ? "put" : "post";
    axios[method](baseUrl + url, data, { withCredentials: true })
      .then((res) => {
        this.setState({
          subject_scheme: "",
          subject_name: "",
          subject_code: "",
          semester: "",
          file: "",
          file_err_msg: "",
          showModal: false,
          isModalLoading: false,
          isDisabled: false,
        });
        alert(
          this.state.isEdit
            ? "Subject Updated Successfully"
            : "Subject(s) Added Successfully"
        );
        this.getSubjects();
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isModalLoading: false,
        });

        // Check if the error is due to duplicate subject code with the same scheme
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "This subject code is already associated with the same scheme"
        ) {
          alert("This subject code is already associated with the same scheme. Please use a different code or scheme.");
        } else {
          // Handle other errors
          alert("An error occurred: This subject code is already associated with the same scheme. Please use a different code or scheme.");
        }
      });
  };

  ToggleModalHandler = () => {
    this.setState((prevState) => {
      return {
        showModal: !prevState.showModal,
        // Reset fields when opening the modal for adding a new subject
        scheme: "",
        subject_name: "",
        subject_code: "",
        degree: "",
        semester: "",
        department: "",
        isEdit: false,
        currentSubject: null,
        file: "",
        file_err_msg: "",
        sems: [],
      };
    });
  };

  closeModalHandler = () => {
    this.setState({ showModal: false });
  };

  editUserHandler = (e, subject) => {
    const sems =
      subject.degree === "M.Tech" ? [1, 2] : [1, 2, 3, 4, 5, 6, 7, 8];

    this.setState({
      showModal: true,
      isEdit: true,
      scheme: subject.scheme,
      subject_name: subject.name,
      subject_code: subject.code,
      degree: subject.degree,
      sems: sems,
      semester: subject.semester,
      department: subject.department._id,
      subId: subject._id,
      currentSubject: subject,
    });
  };

  deleteUserHandler = (e, id) => {
    const subjects = [id];
    if (window.confirm("Are you sure you want to delete the subject?")) {
      let data = { subjects: subjects };
      axios
        .post(baseUrl + "/subjects/delete", data, { withCredentials: true })
        .then((res) => {
          this.getSubjects();
          document.querySelector("#searchbar").value = "";
          this.setState({ filteredSubjects: this.state.subjects });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  degreeChangeHandler = (e) => {
    this.getDepts(e.target.value);
    const sems =
      e.target.value === "M.Tech" ? [1, 2] : [1, 2, 3, 4, 5, 6, 7, 8];
    this.setState({
      degree: e.target.value,
      sems,
      department: "",
      semester: "",
    });
  };

  schemeChangeHandler = (e) => {
    this.setState({ scheme: e.target.value });
  };

  departmentChangeHandler = (e) => {
    this.setState({ department: e.target.value });
  };

  semesterChangeHandler = (e) => {
    this.setState({ semester: e.target.value });
  };

  handleChangePage = (e, newpage) => {
    this.setState({ page: newpage });
  };

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: +e.target.value });
    const temp = this.state.subjects.slice(0, e.target.value);
    this.setState({ filteredSubjects: [...temp] });
    this.setState({ page: 0 });
  };

  handleInput = (e) => {
    if (e.target.files) {
      this.setState({
        file: e.target.files,
        isDisabled: e.target.files.length > 0 ? true : false,
        file_err_msg: "",
      });
    }
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return (
      <div className="main-container" style={{ margin: "10px auto" }}>
        <Typography
          align="center"
          style={{
            fontStyle: "Courier,Arial,sans-shreiff,TimesNewRoman",
            marginTop: "20px",
            color: "#a02929",
            textTransform: "uppercase",
          }}
          color="textSecondary"
          variant="h4"
        >
          Manage Subjects
        </Typography>

        <div style={{ width: "95%", margin: "0 auto" }}>
          <Button
            variant="contained"
            onClick={this.ToggleModalHandler}
            style={{
              marginTop: "10px",
              marginLeft: "auto",
              display: "block",
              color: "#a02929",
              backgroundColor: "#fff",
              border: "0.2px solid #a02929",
            }}
          >
            Add new Subject
          </Button>
          <Paper style={root}>
            <InputBase
              style={input}
              id="searchbar"
              placeholder="Search By Scheme, Subject name, code, department or semester"
              inputProps={{
                "aria-label": "Search By Scheme, Subject name or Subject code",
              }}
              onChange={this.searchBarHandler}
            />
            <IconButton style={iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>

        <TableContainer
          component={Paper}
          style={{ width: "95%", margin: "15px auto" }}
        >
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>Full Name</StyledTableCell> */}
                <StyledTableCell>Scheme</StyledTableCell>
                <StyledTableCell>Subject Name</StyledTableCell>
                <StyledTableCell>Course Code</StyledTableCell>
                <StyledTableCell>Graduation Level</StyledTableCell>
                <StyledTableCell>Semester</StyledTableCell>
                <StyledTableCell>Department</StyledTableCell>
                <StyledTableCell>Edit Subject</StyledTableCell>
                <StyledTableCell>Delete Subject</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.filteredSubjects[0] === "No Entries" ? (
                <StyledTableRow>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}>
                      No Subjects Found
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ) : (
                this.state.filteredSubjects
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                  )
                  .map((subject) => (
                    <StyledTableRow key={subject._id} className="one-user">
                      <StyledTableCell>{subject.scheme} </StyledTableCell>
                      <StyledTableCell>{subject.name} </StyledTableCell>
                      <StyledTableCell>{subject.code}</StyledTableCell>
                      <StyledTableCell>{subject.degree}</StyledTableCell>
                      <StyledTableCell>{subject.semester}</StyledTableCell>
                      <StyledTableCell>
                        {subject.department.Dept_Name}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          onClick={(e) => {
                            this.editUserHandler(e, subject);
                          }}
                          variant="contained"
                          style={{ backgroundColor: "#a02929", color: "#fff" }}
                        >
                          <Edit fontSize="small" />{" "}
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          onClick={(e) => {
                            this.deleteUserHandler(e, subject._id);
                          }}
                          variant="contained"
                          style={{ backgroundColor: "#a02929", color: "#fff" }}
                        >
                          <Delete fontSize="small" />{" "}
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {this.state.showPagination ? (
          <TablePagination
            component="div"
            count={this.state.filteredSubjects.length}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        ) : null}

        <div
          className="form-box"
          style={{
            transform: this.state.showModal
              ? `translateY(0) translateX(-50%)`
              : `translateY(-100vh) translateX(-50%)`,
            opacity: this.state.showModal ? `1` : `0`,
          }}
        >
          <div
            style={{
              position: "relative",
              top: "0",
              left: "0",
              width: "100%",
              textAlign: "center",
              backgroundColor: "#911717",
              color: "#fff",
              padding: "10px",
              fontSize: "24px",
              marginBottom: "10px",
            }}
          >
            {this.state.isEdit ? "Update Subject" : "Add Subject(s)"}
            {this.state.isModalLoading ? (
              <CircularProgress
                size={28}
                style={{ color: "#fff", float: "right" }}
              />
            ) : null}
          </div>
          <form onSubmit={this.submitHandler}>
            <FormControl>
              <InputLabel id="scheme-label">Scheme</InputLabel>
              <Select
                labelId="scheme-label"
                value={this.state.scheme} // Scheme state binding
                MenuProps={MenuProps}
                onChange={this.schemeChangeHandler}
              >
                <MenuItem value={"I"}>I</MenuItem>
                <MenuItem value={"II"}>II</MenuItem>
                <MenuItem value={"II-B"}>II-B</MenuItem>
                <MenuItem value={"III"}>III</MenuItem>
              </Select>
            </FormControl>
            <TextField
              required
              id="standard-required"
              disabled={this.state.isDisabled}
              label="Subject Name"
              name="subject_name"
              onChange={this.inputChangeHandler}
              value={this.state.subject_name}
            />
            <TextField
              required
              id="standard-required"
              disabled={this.state.isDisabled}
              label="Course Code"
              type="text"
              name="subject_code"
              onChange={this.inputChangeHandler}
              value={this.state.subject_code}
            />
            {/* <TextField required id="standard-required" label="semester" type="text" name="semester" onChange={this.inputChangeHandler} value={this.state.semester} /> */}
            <FormControl>
              <InputLabel id="graduation-level-label">
                Graduation Level
              </InputLabel>
              <Select
                disabled={this.state.isDisabled}
                labelId="graduation-level-label"
                value={this.state.degree}
                MenuProps={MenuProps}
                onChange={this.degreeChangeHandler}
              >
                <MenuItem value={"B.Tech"}>B.Tech</MenuItem>
                <MenuItem value={"M.Tech"}>M.Tech</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="semester-label">Semester</InputLabel>
              <Select
                disabled={this.state.isDisabled || this.state.degree === ""}
                labelId="semester-label"
                value={this.state.semester}
                onChange={this.semesterChangeHandler}
                MenuProps={MenuProps}
              >
                {this.state.sems.map((sem) => (
                  <MenuItem key={sem} value={sem}>
                    {sem}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="department-label">Department</InputLabel>
              <Select
                disabled={this.state.isDisabled || this.state.degree === ""}
                labelId="department-label"
                value={this.state.department}
                onChange={this.departmentChangeHandler}
                MenuProps={MenuProps}
              >
                {this.state.depts.map((dept) => (
                  <MenuItem key={dept._id} value={dept._id}>
                    {dept.Dept_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <br />
            {!this.state.isEdit ? (
            <span
                style={{
                  color: "rgb(160, 41, 41)",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                OR
              </span>) : null}
            <>
              <br />
              {!this.state.isEdit ? (
              <div>
                <Button
                  variant="contained"
                  color="default"
                  className="input"
                  startIcon={<CloudUploadIcon />}
                >
                  <input
                    style={{ cursor: "pointer" }}
                    value={this.state.file ? undefined : ""}
                    onChange={this.handleInput}
                    name="upload"
                    type="file"
                    accept=".csv"
                  ></input>
                  Upload CSV
                </Button>
                <br />
                <br />

                {this.state.file_err_msg ? (
                  Array.isArray(this.state.file_err_msg) ? (
                    <>
                      <TableContainer
                        style={{ padding: "30px" }}
                        component={Paper}
                      >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ color: "red" }}>
                                Errors in the file (rest of the data has been
                                inserted)
                              </TableCell>
                              <TableCell style={{ color: "red" }}>Line</TableCell>
                              <h5> </h5>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.file_err_msg.map((errMsg, index) => (
                              <TableRow align="left" className={"error"}>
                                <TableCell>{errMsg.split("line")[0]}</TableCell>
                                <TableCell>{errMsg.split("line")[1]}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Paper>
                      <span align="left" className={"error"}>
                        {this.state.file_err_msg}
                      </span>
                    </Paper>
                  )
                ) : null}
                {/* <WhiteTextField disabled style={{marginTop:-30,width:"80%"}} error={this.state.file_err_msg}   helperText={this.state.file_err_msg}></WhiteTextField> */}
                
                <br />
              </div>) : null}

              <Button
                variant="contained"
                style={{
                  color: "#a02929",
                  width: "100%",
                  backgroundColor: "#fff",
                  border: "0.2px solid #a02929",
                  margin: "10 0 10 10",
                  marginBottom: "0",
                }}
                type="submit"
              >
                {this.state.isEdit
                  ? "Update Subject"
                  : "Add / Upload Subject(s)"}
              </Button>
              {!this.state.isEdit ? (
              <div>
                <br />
                <br />
                <span className="instructions_heading">
                  Instructions for Upload Format:
                </span>
                <br />
                <ul className="instructions">
                  <li>The only file type accepted is .csv</li>
                  <li>Column Headers are required</li>
                  <li>
                    Column Names needed in the csv file(Case-Sensitive & In Order){" "}
                    <br />
                  </li>
                  <table>
                    <tbody>
                      <tr>
                        <th>Semester</th>
                        <th>Programme</th>
                        {/* <th>gender</th> */}
                        <th>Course Code</th>
                        <th>Course Name</th>
                      </tr>
                    </tbody>
                  </table>
                  {/* <li>Subjects must be separated by 'comma ( , )' in the SubjectData Field Eg. Physics,Maths,Chemistry</li> */}
                  <li>All fields are required</li>
                  <li>Any redundant data will be excluded from insertion</li>
                  <li>
                    You must strictly save your excel file as <b> CSV UTF-8</b> .
                    Do not use any online convertors / other csv formats
                  </li>
                  <li>
                    Sample File :{" "}
                    <a href={sample} download="Sample - Subject List.csv">
                      Sample.csv
                    </a>
                  </li>
                </ul>
              </div>) : null}
            </>
          </form>
        </div>

        <Backdrop
          show={this.state.showModal}
          clicked={this.closeModalHandler}
        />
      </div>
    );
  }
}

export default AddSubject;
